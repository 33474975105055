import React from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { N100, R300 } from '@atlaskit/theme/colors';
import WarningIcon from '@atlaskit/icon/core/warning';

import SearchErrorImage from './assets/SearchErrorImage.svg';

const i18n = defineMessages({
	errorHeader: {
		id: 'space-pages.search.results.error.header',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "We're having trouble getting your results",
		description:
			'Header that appears on the Pages screen when an error occurs during initial load or searching/filtering.',
	},
	errorAction: {
		id: 'space-pages.search.results.error.action',
		defaultMessage: 'Refresh and try again.',
		description:
			'Action text that appears on the Pages screen when an error occurs during initial load or searchin/filtering.',
	},
	errorCombined: {
		id: 'space-pages.search.results.error.combined',
		defaultMessage: 'We’re having trouble getting your results. Refresh and try again.',
		description:
			"Subtle text that appears on the Pages screen below existing page cards when pagination doesn't work.",
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GenericErrorContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '68px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GenericErrorHeader = styled.h3({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '37px',
});

const genericErrorActionStyles = xcss({
	marginTop: 'space.200',
});

export const GenericError = () => (
	<GenericErrorContainer>
		<img src={SearchErrorImage} alt="" />
		<GenericErrorHeader>
			<FormattedMessage {...i18n.errorHeader} />
		</GenericErrorHeader>
		<Box xcss={genericErrorActionStyles}>
			<FormattedMessage {...i18n.errorAction} />
		</Box>
	</GenericErrorContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PaginationErrorContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: token('space.250', '20px'),
	color: token('color.text.subtlest', N100),
});

export const PaginationError = () => (
	<PaginationErrorContainer>
		<WarningIcon spacing="spacious" color={token('color.icon.danger', R300)} label="" />
		<FormattedMessage {...i18n.errorCombined} />
	</PaginationErrorContainer>
);
