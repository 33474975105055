import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import EmptyStateImage from './assets/EmptyStateImage.svg';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyContainer = styled.div({
	marginTop: token('space.800', '64px'),
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyHeader = styled.h3({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '35px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyBody = styled.div({
	marginTop: token('space.200', '16px'),
	width: '395px',
	textAlign: 'center',
});

const i18n = defineMessages({
	emptyHeader: {
		id: 'space-pages.search.results.empty.state.header',
		defaultMessage: 'Bring this space to life',
		description:
			'Descriptive header that appears on the Pages/Content screen when no Pages/Content exist in a space.',
	},
	emptyBodyPages: {
		id: 'space-pages.search.results.empty.state.body.pages',
		defaultMessage:
			'Once content is created, this will fill up with cards that let you easily scan details to help you figure out where you want to go next.',
		description:
			'Descriptive body text that appears on the Pages screen when no Pages exist in a space.',
	},
	emptyBodyContent: {
		id: 'space-pages.search.results.empty.state.body.content',
		defaultMessage:
			'Once content is created, this will fill up with cards to easily scan details for where you want to go next.',
		description:
			'Descriptive body text that appears on the Content screen when no Content exist in a space.',
	},
});

export const Empty: FC<{ isWhiteboardEnabled?: boolean }> = ({ isWhiteboardEnabled = false }) => (
	<EmptyContainer>
		<img src={EmptyStateImage} alt="" />
		<EmptyHeader>
			<FormattedMessage {...i18n.emptyHeader} />
		</EmptyHeader>
		<EmptyBody>
			<FormattedMessage {...(isWhiteboardEnabled ? i18n.emptyBodyContent : i18n.emptyBodyPages)} />
		</EmptyBody>
	</EmptyContainer>
);
